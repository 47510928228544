import React from 'react'

const Progressbar = ({ current, total }) => {
    return (
        <>
            <div className='text-center'>
                <div class="progress">
                    <div class="progress-bar bg-warning" role="progressbar" style={{ width: `${(parseInt((current / total) * 100))}%` }} aria-valuenow={(parseInt((current / total) * 100))} aria-valuemin="0" aria-valuemax="100">{(parseInt((current / total) * 100))}%</div>
                </div>
                <h6 className='text-center mt-2'><div class="spinner-border text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                </div>   </h6></div>
        </>
    )
}

export default Progressbar