import React, { useState, useEffect } from 'react';
import './hero.css';
import { Button, Loader } from '@mantine/core';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BellaCasa from '../../logo/LOGO.jpg'
import FApp from './FacebookFrames/Index';
import Fdh from '../../logo/FRIENDS HOTEL LOGO.png'
const HeroIndex = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const [randomBackgroundImage, setRandomBackgroundImage] = useState('https://ik.imagekit.io/ghsourav/FriendsHotel/hero/237055550.jpg_k=8e4ead78bf905fff447d7f4b84327f27b43dfaa2ef7e5d4176493fc1e13e7adc&o=?updatedAt=1691304187309');
  const [randomText, setRandomText] = useState('Crafting Everlasting Bonds of Friendship');

  const text = ["Create Lasting Memories: Stay Once, Treasure Forever", "তৈরি করি চিরকালীন ঘুরে বেড়ানোর বন্ধুত্বের সম্পর্ক", "Crafting Everlasting Bonds of Friendship: Embrace Memories That Travel Through Time - Your Ultimate Hotel Experience!", "Crafting Endless Bonds: Your Gateway to Everlasting Hospitality"]

  const getRandomBackgroundImage = () => {
    const randomIndex = Math.floor(Math.random() * backgroundImageUrls.length);
    setRandomBackgroundImage(backgroundImageUrls[randomIndex]);
    const randomTextIndex = Math.floor(Math.random() * text.length);
    setRandomText(text[randomTextIndex]);

  };

  const dataObj = {
    h1: "Friends Hotel",
    h2: "Best Hotel in Pelling,Sikim",
    p: randomText,
    heroDescription: "Norbulingka Retreat Pelling Best valueable family Hotel ",
  };


  useEffect(() => {
    // Simulating loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 34);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);


  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Call your function here
      getRandomBackgroundImage()
    }, 3000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array to run the effect only once

  const backgroundImageUrls = [
    'https://ik.imagekit.io/ghsourav/FriendsHotel/hero/WhatsApp%20Image%202023-07-31%20at%2002.55.24.jpeg?updatedAt=1691261401613',
    'https://ik.imagekit.io/ghsourav/FriendsHotel/hero/237055550.jpg_k=8e4ead78bf905fff447d7f4b84327f27b43dfaa2ef7e5d4176493fc1e13e7adc&o=?updatedAt=1691304187309',
    'https://ik.imagekit.io/ghsourav/FriendsHotel/Sikkim/Sikkim-5.jpeg?updatedAt=1694971161104'
  ];

  const heroImageUrl = randomBackgroundImage;
  return (
    <main>
      <Helmet>
        <title>Norbulingka Retreat Pelling|Friends Hotel|Best Hotel in Pelling</title>
        <meta name="description" content={dataObj.p} />
        {/* You can add more meta tags specific to this component */}
        <meta property="og:title" content={dataObj.h1} />
        <meta property="og:description" content={dataObj.heroDescription} />
        <meta property="og:image" content={heroImageUrl} />
        {/* Add more meta tags as needed for your SEO */}
        <img
          src={randomBackgroundImage}
          alt="Random Background"
          style={{ display: 'none' }}
          onLoad={handleImageLoad}
        />
      </Helmet>
      <section className='n p-3'>
        <main className='container text-center '>
          <h4 className='text-hotel Ysabeau mt-3 text-center'>Explore All Our Hotels</h4>
          <div className='row'>

             <Link className="col-sm-5 text-hotel Ysabeau btn btn-light" to="/Hotel/Pelling">
         
              Pelling
            </Link>
            <h5 className='col-sm-2 ' ></h5>

            <Link className="col-sm-5 text-hotel Ysabeau btn btn-light" to="/Hotel/Norbulingka-Retreat">
          
              Pelling
            </Link>
          </div>


        </main>
      </section>

      {loading ? <section className="hero-loader">
        <div className="loader-container">
          <p></p>
          <Loader color="yellow" size="xl" variant="bars" />
          <p></p>
        </div>
      </section> : <section className={isMobile ? "hero-m" : "hero"}>

        <div className="jumbotron jumbotron-fluid " >
          <div className="container add">
            <section className='row'>
              <main className='col  col-sm-6'>
                <h4 className='text-light'>Welcome To</h4>
                <h1 className="display-h1">{dataObj.h1}</h1>
                <h2 style={{ display: 'none' }}>{dataObj.h2}</h2>
                <br />
                <b className='text-dark'>{dataObj.p}</b>
              </main>
              <main className={isMobile ? "dpnone" : "col col-sm-6"}>
                <img className='img-random img-thumbnail'
                  src={randomBackgroundImage}
                  onLoad={handleImageLoad}
                />
              </main>
            </section>
          </div>
        </div>
      </section>}

    </main>
  );
};

export default HeroIndex;