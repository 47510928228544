
import React, { useEffect } from 'react';
import Navbar from './client/Navbar';
import { Helmet } from 'react-helmet';
const Map = () => {
    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            window.location.replace("https://maps.app.goo.gl/tMuZBSKpyV4q74WaA");
        }, 9); // Delay of 2000 milliseconds (2 seconds)

        // Clear the timer if the component unmounts before the delay completes
        return () => clearTimeout(redirectTimer);
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    return (
        <div>
            <Helmet>
                <title>FriendsHotel.In Map Location</title>
                <meta name="description" content={'Best value for money hotels is  operated by Friendshotel.in, in Sikkim , India'} />
                <meta property="og:title" content={'FriendsHotel.In Map Location'} />
                <meta property="og:description" content={'Best value for money hotels in Sikkim ,-' + 'FriendsHotel.In Map Location'} />
            </Helmet>
            <Navbar />
            <h1>Google locations of FriendsHotel</h1>
        </div>
    );
}

export default Map;

