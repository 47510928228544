import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../../logo/logo.png'
import {
    Card,
    TextInput,
    Button,
    Avatar,
    useMantineTheme,
    MANTINE_COLORS,
} from '@mantine/core';
import Navbar from '../Navbar';
import './LoginIndex.css'

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const theme = useMantineTheme();

    const handleLogin = () => {
        // Here you can implement your login logic.
        // For demonstration purposes, we'll just log the values.
        // console.log('Email:', email);
        // console.log('Password:', password);
        alert("Wrong Email/Password")
    };

    const handleEmailChange = (event) => {
        setEmail(event.currentTarget.value);

        // Validate email format using a simple regex
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.currentTarget.value);
        setIsEmailValid(isValid);
    };

    const dataObj = {
        h1: 'Hotel Booking in Gangtok and Pelling',
        p: 'Book your hotel in Gangtok and Pelling with the best rates and excellent amenities.',
        heroDescription: 'Find the perfect hotel for your stay in Gangtok and Pelling.',
        url: 'http://friendshotel.in/'
    };

    return (
        <>
           <Helmet>
                <title>Login {dataObj.h1}</title>
                <meta name="description" content={dataObj.p} />
                <meta property="og:title" content={dataObj.h1} />
                <meta property="og:description" content={dataObj.heroDescription} />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content={dataObj.url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Friends Hotel" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={dataObj.h1} />
                <meta name="twitter:description" content={dataObj.heroDescription} />
                <meta name="twitter:image" content={Logo} />
                <meta name="keywords" content="Hotel Booking, Gangtok, Pelling, Hotel, Accommodation, Travel" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Ghsourav" />
                <h1>{dataObj.h1}</h1>
                <h2>{dataObj.heroDescription}</h2>
                <p>{dataObj.p}</p>
            </Helmet>
            <Navbar />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '80vh',
                    background: '#f0f0f0',
                }}
            >
                <Card
                    shadow="xl"
                    style={{
                        width: 400,
                        padding: 30,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Avatar size="xl" src={Logo} />
                    <h3 className='text-orange'>Friends Hotel</h3>
                    <div className='ghsourav-login-input'>
                        <TextInput
                            required
                            label="Email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            error={!isEmailValid}
                            errorLabel="Please enter a valid email address"
                            style={{ borderColor: !isEmailValid ? theme.colors[`${MANTINE_COLORS.RED}`] : undefined }}
                            color="orange"
                        />
                    </div>
                    <div className='ghsourav-login-input'>
                        <TextInput
                            required
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(event) => setPassword(event.currentTarget.value)}
                            color="orange"
                        />
                    </div>
                    <Button fullWidth color="orange" onClick={handleLogin} disabled={!isEmailValid}>
                        Login
                    </Button>
                </Card>
            </div>
        </>
    );
};

export default LoginPage;
