import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from '../NotFound';
import HomeIndex from '../client/Home/HomeIndex';
import LoginIndex from '../client/Login/LoginIndex';
import thingstodo from '../client/Home/thingstodo'
import Blog from '../client/Home/blog/blogIndex'
import About from '../client/About/AboutIndex'
import Hotels from '../client/Home/Hotels';
import HotelPageIndex from '../client/HotelPages/HotelPageIndex';
import tourplanIndex from '../client/tour/tourplanIndex';
import Map from '../Map'
import RoomTour from '../client/Redirect/RoomTour';
import tourDetails from '../client/tourDetails';
import Ravangla from '../client/Redirect/Ravangla';
function App() {
    return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HotelPageIndex} />
                    <Route exact path="/Norbulingka/RoomTour" component={RoomTour} />
                    <Route exact path="/r/ravangla" component={Ravangla} />
                    <Route exact path="/Map" component={Map} />
                    <Route exact path="/Blog" component={Blog} />
                    <Route exact path="/Login" component={LoginIndex} />
                    <Route exact path="/Blog/:language/:id" component={thingstodo} />
                    <Route exact path="/about/" component={About} />
                    <Route exact path="/our-hotels" component={Hotels} />
                    <Route exact path="/Album" component={HomeIndex} />
                    <Route exact path="/tour" component={tourplanIndex} />
                    <Route exact path="/Hotel/:name" component={HotelPageIndex}/>
                    <Route exact path="/:name" component={HotelPageIndex}/>
                    <Route exact path="/tour/:path" component={tourDetails} />

                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
    );
}


export default App;