import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Drawer, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import Logo from '../logo/FRIENDS HOTEL LOGO.png'
//import './navbar.css'
const Navbar = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpened(!drawerOpened);
  };
  const location = useLocation()

  const navJson = [{ url: "/", name: "Home" },  { url: '/Hotel/Pelling', name: "Pelling" },  { url: "/tour", name: "Tour" },{ url: "/blog", name: "Blog" },{ url: '/Hotel/Norbulingka-Retreat', name: "Norbulingka Retreat" }]

  let version="0.1.6"

  return (
    <>
      <Drawer opened={drawerOpened} onClose={toggleDrawer} size="xs">
        {navJson.map((n) => <Link to={n.url} style={{ textDecoration: 'none' }}>
          <Button
            size="lg"
            variant={location.pathname === n.url ? "light" : "outline"}
            color="yellow"
            fullWidth
            style={{ marginBottom: '10px' }}
            onClick={toggleDrawer}
          >
            {n.name}
          </Button>
        </Link>)}
        <li className='text-hotel'>v {version}</li>
      </Drawer>


      <main style={{
        display: 'flex',
        alignItems: 'center',
      }}
        className={isMobile ? 'navbar shadow bg-white rounded hahahaha' : 'navbar shadow bg-white rounded '}>


        <Link to="/" style={{ textDecoration: 'none', marginRight: 'auto', marginLeft: '3.5rem' }}>
          <img src={Logo} alt="FriendsHotel.in" className="rounded-circle" style={{ height: '4.5rem' }} />
        </Link>

        {isMobile ?
          <>
            <Button fullWidth
              size="lg"
              variant="outline"
              color="yellow"
              compact
              onClick={toggleDrawer}
              style={{ marginLeft: 'auto', margin: '0.5rem' }}
            >
              <FaBars />
              {" "}
              Menu
            </Button>
          </> : <>
            <nav className='p-2' >
              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                {navJson.map((n) => (
                  <li key={n.url} className={location.pathname === n.url ? 'inActive' : null} style={{ marginRight: '1rem' }}>
                    <Link to={n.url} style={{ textDecoration: 'none', color: 'black' }}>
                      {n.name}
                    </Link>
                  </li>
                ))}
                <li className='text-hotel'>v {version}</li>
              </ul>
            </nav>
          </>}



      </main >
    </>
  );
};

export default Navbar;