import React, { useEffect } from 'react';
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet';
const RoomTour = () => {

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      window.location.replace("https://drive.google.com/drive/u/0/folders/1-eKllDG8LnoHaPIvjUWDq2OKy7lStaZV");
    }, 9); 
    return () => clearTimeout(redirectTimer);
  }, []); 
  return (

    <>

      <Helmet>
        <title>Norbulingka Retreat
          Room  Tour</title>
        <meta name="description" content={'Best value for money hotels is  operated by Friendshotel.in, in Sikkim , India'} />
        <meta property="og:title" content={'FriendsHotel.In Map Location'} />
        <meta property="og:description" content={'Best value for money hotels in Sikkim ,-' + 'FriendsHotel.In Map Location'} />
      </Helmet>
      <Navbar />
      <h1>Norbulingka Retreat Room Tour</h1>
    </>
  )
}


export default RoomTour