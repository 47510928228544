import React from 'react'
import './view.css'
import Logo from '../../logo//FRIENDS HOTEL LOGO.png'
import Bellacasa from '../../logo/Bellacasa.png'
import { isMobile } from 'react-device-detect'
const ViewCrads = () => {
    return (
        <section className='p-3'>

            {isMobile ? <>
                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6">
                        <h6 className='mt-4'>
                            "Hill View Retreat: Serenity, Scenic Beauty, and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Enjoy View from our Hotel
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            <br />
                            Norbulingka Retreat

                        </h6>
                    </div>
                    <div className="viewCard col-sm-6 bbb col-md-6 col-lg-6">

                    </div>
                </div>
                <div className="row">

                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 bg-light">
                        <h6 className='mt-4'>
                            "Hospitality Redefined: Elevating the Experience and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Explore our in restaurants
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            Restaurant
                        </h6>

                    </div>
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6  fine-dinning">

                    </div>
                </div>

                <div className="row">

                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 bg-light">
                        <h6 className='mt-4'>
                            "Experience Tranquility in the Lap of the Majestic Himalayas"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Enjoy View from our Premier Room
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            <br />
                            <image width={80} height={80} className='img-fluid p-2' src={Bellacasa} alt="Bellacasa" />
                            Hotel Bella Casa
                        </h6>

                    </div>
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 gangtok">

                    </div>
                </div>
                
                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6">
                        <h6 className='mt-4'>
                            "Your Journey, Our Commitment and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Book Flight,Car & many more . .
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            Travel Desk
                        </h6>
                    </div>
                    <div className="viewCard col-sm-6 travel col-md-6 col-lg-6">

                    </div>
                </div>


            </> : <>
                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 gangtok">

                    </div>
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 bg-light">
                        <h6 className='mt-4'>
                            "Experience Tranquility in the Lap of the Majestic Himalayas"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Enjoy View from our Premier Room
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            <br />
                            <image width={80} height={80} className='img-fluid' src={Bellacasa} alt="Bellacasa" />
                            Hotel Bella Casa
                        </h6>

                    </div>
                </div>

                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6">
                        <h6 className='mt-4'>
                            "Hill View Retreat: Serenity, Scenic Beauty, and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Enjoy View from our Hotel
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid' src={Logo} alt='Friends Hotel' />
                            <br />
                            Norbulingka Retreat

                        </h6>
                    </div>
                    <div className="viewCard col-sm-6 bbb col-md-6 col-lg-6">

                    </div>
                </div>

                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6  fine-dinning">

                    </div>
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6 bg-light">
                        <h6 className='mt-4'>
                            "Hospitality Redefined: Elevating the Experience and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Explore our in restaurants
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            Restaurant
                        </h6>

                    </div>
                </div>



                <div className="row">
                    <div className="viewCard col-sm-6 col-md-6 col-lg-6">
                        <h6 className='mt-4'>
                            "Your Journey, Our Commitment and Beyond"
                        </h6>
                        <h2 className='mt-4 my-h2'>
                            Book Flight,Car & many more . .
                        </h2>
                        <h6>
                            At
                            <img width={100} className='img-fluid p-2' src={Logo} alt='Friends Hotel' />
                            Travel Desk
                        </h6>
                    </div>
                    <div className="viewCard col-sm-6 travel col-md-6 col-lg-6">

                    </div>
                </div>




            </>}


        </section>

    )
}

export default ViewCrads