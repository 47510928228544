import React, { createContext, useContext, useState } from 'react';

export const OpenContext = createContext();

export function useApi() {
    return useContext(OpenContext);
}

export function OpenProvider({ children }) {
    const [user, setUser] = useState(null);
    const [Album, setAlbum] = useState(null);
    const [isLogin,setisLogin]=useState(false)
    const login = (userData) => {
        // Logic to handle login and set user data
        setUser(userData);
        setisLogin(true)
    };

    const logout = () => {
        // Logic to handle logout and reset user data
        setUser(null);
    };

    const contextValue = {
        user,
        login,
        logout,
        Album, 
        setAlbum,
        isLogin
    };

    return (
        <OpenContext.Provider value={contextValue}>
            {children}
        </OpenContext.Provider>
    );
}
