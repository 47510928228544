import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import tourDetails from './tourpakage.json';
import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar';
import './tour.css'
import { ShareSocial } from 'react-share-social'

const TourplanIndex = () => {


    return (
        <>
            <Helmet>
                <title>Tour Plan of North Bengal & Sikkim ||Best Kanchenjunga view rooms from Pelling</title>
                <meta name="description" content="Tour Plan of North Bengal & Sikkim ||Best Kanchenjunga view rooms from Pelling" />
                {/* You can add more meta tags specific to this component */}
                <meta property="og:title" content="Tour Plan of Sikkim ||Best Kanchenjunga view rooms from Pelling" />
                <meta property="og:description" content="Tour Plan of North Bengal & Sikkim ||Best Kanchenjunga view rooms from Pelling" />
                <meta property="og:image" content="https://scontent.fccu2-1.fna.fbcdn.net/v/t39.30808-6/430943327_257979157393712_4340968020502832615_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=5f2048&_nc_ohc=uyxqmP1PhEwAX_kueQ3&_nc_ht=scontent.fccu2-1.fna&oh=00_AfBHBZFmJ4xmpm5eb-AHDmmj70LEO22-VoK3YguWa5K90Q&oe=65F297C1" />
                {/* Add more meta tags as needed for your SEO */}
                <img
                    src="https://scontent.fccu2-1.fna.fbcdn.net/v/t39.30808-6/430943327_257979157393712_4340968020502832615_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=5f2048&_nc_ohc=uyxqmP1PhEwAX_kueQ3&_nc_ht=scontent.fccu2-1.fna&oh=00_AfBHBZFmJ4xmpm5eb-AHDmmj70LEO22-VoK3YguWa5K90Q&oe=65F297C1"
                />
            </Helmet>
            <Navbar />
            <section className='row tour-hero'>
                <h3 className='text-center mt-3 mb-3'>
                    <Link className="link" to="/">Home</Link>/<Link className="link" to="/Tour">Tour</Link>
                </h3>


            </section>
            <div className="container">


                <div className="row">

                    {tourDetails.map((d, index) => <>
                        <main className="col-md-4 mb-4">
                            <Card shadow="sm" padding="lg" radius="md" withBorder key={index}>
                                <Card.Section>
                                    <Image
                                        src={d.images}
                                        alt={d.name}
                                        width={300}
                                        height={300}
                                        className='bg-loader'
                                    />
                                </Card.Section>

                                <Group justify="space-between" mt="md" mb="xs">
                                    <Text fw={500}>{d.name}</Text>
                                    <Badge color="pink">On Sale</Badge>
                                </Group>

                                <Text size="sm" c="dimmed">
                                    Start From   {d.stats} /Person
                                </Text>

                                

                                <a target='_blank' href={`https://wa.me/918777209081?text=-- Start From ${d.stats}  Details https://friendshotel.in/tour/${d.slug} `}>
                                    <Button color="blue" fullWidth mt="md" radius="md">
                                        Book your tour now
                                    </Button>
                                </a>
                                <a target='_blank' href={`/tour/${d.slug}`}>
                                    <Button color="blue" fullWidth mt="md" radius="md">
                                        View Tour Details
                                    </Button>
                                </a>

                                or Call


                            </Card>
                        </main>
                    </>)}

                </div>


            </div>
        </>

    );
};

export default TourplanIndex;
