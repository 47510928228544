import React from 'react';
import data from './AboutDat.json';
import Navbar from '../Navbar';
import Helmet from 'react-helmet';

const AboutUs = () => {
    const { foundingYear, partners, hotels, commitment, philosophy } = data;

    return (
        <>
            <Helmet>
                <title>About Us - Friends Hotel</title>
                <meta name="description" content="Welcome to Friends Hotel! We are delighted to have you here and share the story of our hospitality venture." />
                <meta name="keywords" content={philosophy.replace(/ /g, ",")}/>
                <meta name="keywords" content={commitment.replace(/ /g, ",")}/>
                {/* Add more SEO meta tags as needed */}
                <meta property="og:title" content="About Us - Friends Hotel" />
                <meta property="og:description" content="About Us - We are delighted to have you here and share the story of our hospitality venture." />
                {/* <meta property="og:image" content="https://example.com/friends-hotel-image.jpg" />
        <meta property="og:url" content="https://example.com/about" /> */}
                <h1>About Us Friends Hotel!</h1>
                <h5>
                    In {foundingYear}, {partners} partners came together with a shared passion for providing exceptional hospitality experiences in the mesmerizing region of Gangtok. United by our love for this beautiful place and a common vision, we established Friends Hotel as a partnership firm. Our journey began with the ambition to create a warm and welcoming space for travellers seeking comfort, tranquility, and genuine connections.
                </h5>
                <h2>Our Hotels:</h2>
                {hotels.map((hotel, index) => (
                    <div key={index}>
                        <h3>{hotel.name}</h3>
                        {/* {hotel.website && <a href={hotel.website} target="_blank" rel="noopener noreferrer">Website</a>} */}
                        <h2>{hotel.description}</h2>
                    </div>
                ))}
                <h2>Our Commitment:</h2>
                <h2>{commitment}</h2>

                <h2>Our Philosophy:</h2>
                <h2>{philosophy}</h2>
            </Helmet>
            <Navbar />
            
            <div className='container m-5'>

                <h1 className='mt-4'>Welcome to Friends Hotel!</h1>
                <p>
                    In {foundingYear}, {partners} partners came together with a shared passion for providing exceptional hospitality experiences in the mesmerizing region of Gangtok. United by our love for this beautiful place and a common vision, we established Friends Hotel as a partnership firm. Our journey began with the ambition to create a warm and welcoming space for travellers seeking comfort, tranquility, and genuine connections.
                </p>
                <h2>Our Hotels:</h2>
                {hotels.map((hotel, index) => (
                    <div key={index}>
                        <h3>{hotel.name}</h3>
                        {hotel.website && <a href={hotel.website} target="_blank" rel="noopener noreferrer">Website</a>}
                        <p>{hotel.description}</p>
                    </div>
                ))}

                <h2>Our Commitment:</h2>
                <p>{commitment}</p>

                <h2>Our Philosophy:</h2>
                <p>{philosophy}</p>

                <h2>Contact Us:</h2>
                <p>If you have any inquiries, suggestions, or would like to experience the charm of Friends Hotel firsthand, please don't hesitate to get in touch with us. You can reach out to our team through the contact details provided on our website (friendshotel.in) or via email at info@friendshotel.in.</p>

                <p>Thank you for considering Friends Hotel for your stay in Gangtok. We look forward to hosting you and creating lasting memories together!</p>
            </div>
        </>

    );
};

export default AboutUs;
