// react 17.0.2

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Load environment variables from .env file
// // Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// // Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);