import React from 'react'
import Navbar from '../Navbar'
import HeroIndex from './HeroIndex'
import './home.css'
import Logo from '../../logo/logo.png'
import OurProperties from '../Property/Property'
import Info from '../info'
import ViewCrads from './ViewCrads'
import Facility from './ActivitiesAndServices'


const HomeIndex = () => {
    return (
        <>
            <Navbar />
            <HeroIndex />
            <Facility />
            <ViewCrads/>
        </>
    )
}

export default HomeIndex