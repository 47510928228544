import React, { useEffect, useState } from 'react';
import { SimpleGrid, Skeleton } from '@mantine/core';
import '../Property/Property.css'

const activitiesAndServices = [
    {
        icon: 'fas fa-clock',
        name: 'Convenient Online Booking',
        info: 'Experience the ease of 24/7 online booking and personalized guest assistance. Whether it’s the break of dawn or midnight, our doors are always open for you. Visit https://friendshotel.in/ and book your stay for a warm and delightful welcome.'
    },    
    {
        icon: 'fas fa-coffee',
        name: 'Complimentary Tea / Coffee',
        info: 'Enjoy complimentary tea and coffee during your stay. Start your day with a refreshing cup of tea or coffee, or indulge in a warm beverage at any time of the day.'
    },
    {
        icon: 'fas fa-bed',
        name: 'King & Queen Beds',
        info: 'Experience ultimate comfort with our spacious king and queen beds. Relax and unwind in our well-appointed rooms, ensuring a restful sleep during your stay.'
    },
    {
        icon: 'fas fa-wifi',
        name: 'WI-FI Internet Access',
        info: 'Stay connected with high-speed Wi-Fi internet access. Whether you need to check your emails, browse the web, or stream your favorite shows, our reliable internet service has got you covered.'
    },
    {
        icon: 'fas fa-map-marked-alt',
        name: 'Travel Desk Help',
        info: 'Our dedicated travel desk is available to assist you with all your travel needs. From providing information about local attractions to arranging tours and transportation, our staff is here to ensure a memorable travel experience.'
    },
    // {
    //     icon: "fas fa-bolt",
    //     name: "Power Backup",
    //     info: "Rest assured with our 24-hour power backup facility. Even during power outages, you can continue to enjoy a comfortable stay without any interruptions."
    // },
    // {
    //     icon: "fas fa-elevator",
    //     name: "Lift Facility",
    //     info: "Our hotel offers a convenient lift facility, making it easy for guests to access different floors. Say goodbye to climbing stairs and enjoy a hassle-free stay."
    // },
    // {
    //     icon: "fas fa-spa",
    //     name: "SPA\n(Coming Soon)",
    //     info: "Indulge in relaxation and rejuvenation with our upcoming spa facility. Experience a range of spa treatments and therapies designed to enhance your well-being and provide ultimate relaxation."
    // },
    {
        icon: "fas fa-building",
        name: "Corporate Events",
        info: "Host your next corporate event at our venue. With a tranquil ambiance and contemporary setting, our venue is perfect for conferences, seminars, workshops, and business meetings. We offer a great variety of services and amenities to cater to your event needs. Contact us for more information."
    },
    {
        icon: "fas fa-utensils",
        name: "In-house Restaurant",
        info: "Savor delectable culinary delights at our in-house restaurant. Enjoy a diverse menu of local and international cuisines, prepared by skilled chefs using the finest ingredients."
    },
    // {
    //     icon: "fas fa-cocktail",
    //     name: "Mini Bar\n(Coming Soon)",
    //     info: "Set in a tranquil ambiance, our contemporary lounge and bar offer a great variety of premium imported and Indian spirits, innovative cocktails, and hot & tangy lip-smacking snacks. Unwind and enjoy a delightful evening with friends or colleagues."
    // },
    {
        icon: "fas fa-car",
        name: "Pickup/Drop Facility",
        info: "Enjoy our convenient and reliable pickup/drop service, available for a seamless journey in Gangtok, Siliguri, and Bagdogra. Whether arriving or departing, make your travel hassle-free. Book now and experience a smoother and more enjoyable trip.Contact Travel Desk more info"
    },
    // {
    //     icon: "fas fa-ring",
    //     name: "Wedding Events",
    //     info: "Make your special day unforgettable with our exquisite wedding events services. From elegant decor and personalized themes to impeccable catering and professional event management, we ensure every detail is taken care of. Celebrate the beginning of your journey together in style and create beautiful memories with your loved ones."
    // }
];

const ActivitiesAndServices = ({data}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1456);

        // Clean up the timer on component unmount
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <h1 className='text-center text-light display-none'>AMENITIES</h1>
            {activitiesAndServices.map((facility, index) => (
                <div key={index} className='display-none'>
                    <h3 className='display-none'>
                        {facility.name}
                    </h3>
                    <h4 className='display-none'>
                        {facility.info}
                    </h4>
                </div>
            ))}

            {loading ? <main className='p-4 text-light try'>

                <div className='p-4'>
                    <SimpleGrid
                        cols={4}
                        spacing="lg"
                        breakpoints={[
                            { maxWidth: '62rem', cols: 3, spacing: 'md' },
                            { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                            { maxWidth: '36rem', cols: 1, spacing: 'sm' },
                        ]}
                    >
                        {activitiesAndServices.map((facility, index) => (
                            <div key={index} className='pb-3 t-box shadow mt-2'>
                                <span className='text-center text-warning'>
                                    <Skeleton height={50} circle mb="xl" />

                                    <i className='fa fa-spinner'></i>
                                </span>
                                <span className='text-center text-warning'>
                                    <span>
                                        <Skeleton height={9} mt={6} width="100%" radius="xl" />
                                    </span>
                                </span>
                                <span className='text-center rani-text'>
                                    <Skeleton height={9} mt={6} width="90%" radius="xl" />
                                    <Skeleton height={9} mt={6} width="86%" radius="xl" />
                                    <Skeleton height={9} mt={6} width="82%" radius="xl" />

                                    <Skeleton height={9} mt={6} width="75%" radius="xl" />
                                </span>

                            </div>
                        ))}
                    </SimpleGrid>
                </div></main> : <main className='p-4 text-light try'>
                <h1 className='text-center text-hotel Ysabeau'>AMENITIES</h1>
                <div className='p-4'>
                    <SimpleGrid
                        cols={4}
                        spacing="lg"
                        breakpoints={[
                            { maxWidth: '62rem', cols: 3, spacing: 'md' },
                            { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                            { maxWidth: '36rem', cols: 1, spacing: 'sm' },
                        ]}
                    >
                        {activitiesAndServices.map((facility, index) => (
                            <div key={index} className='pb-3 t-box shadow mt-2'>
                                <p className='text-center text-hotel  myo'>
                                    <i className={facility.icon}></i>
                                </p>
                                <h6 className='text-center aminity-color Handjet-h1 myo'>
                                    {facility.name}
                                </h6>
                                <p className='text-center rani-text  Handjet-h1'>
                                    {facility.info}
                                </p>

                            </div>
                        ))}
                    </SimpleGrid>
                </div></main>}
        </>


    );
};

export default ActivitiesAndServices;