import React from 'react'
import Navbar from '../Navbar'
import './our-hotel.css'
import { isMobile } from 'react-device-detect'
import OurProperties from '../Property/Property'
const Hotels = () => {
    return (
        <>
            <Navbar />
            <main className='sec1 bg-dark'>
                <h1 className={`text-light text-center  sec1-h1 ${isMobile ? 'xl-sec1' : 'xl-sec1'}`}>
                    Our Hotels
                </h1>
            </main>

            <main className='bg-gainsboro-section'>
                <div className='hh mt-2'>
                <OurProperties/>
                </div>
            </main>


        </>
    )
}

export default Hotels