import React, { useEffect } from 'react';
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet';

const Ravangla = () => {
    useEffect(() => {
        const redirectTimer = setTimeout(() => {
          window.location.replace("https://drive.google.com/file/d/1SXgKZpvj-Pn9W-4UL9FyA2uI3M3iagh_/view?usp=drive_link");
        }, 9); 
        return () => clearTimeout(redirectTimer);
      }, []); 
  return (
    <>
      <Helmet>
        <title>Norbulingka Retreat
          Room  Tour</title>
        <meta name="description" content={'Best value for money hotels is  operated by Friendshotel.in, in Sikkim , India'} />
        <meta property="og:title" content={'FriendsHotel.In Ravangla'} />
        <meta property="og:description" content={'Best value for money hotels in Sikkim ,-' + 'FriendsHotel.In Ravangla'} />
      </Helmet>
      <Navbar />
      <h1>Ravangla</h1>
    </>
    
  )
}

export default Ravangla