import React from 'react';
import './App.css';
import { OpenProvider ,useApi} from './context/api'; // Assuming user is exported directly from './context/api'
import Footer from './footer';
import LogOutRoutes from './routes/LogoutRoutes';
import LoginRoutes from './routes/LoginRoutes';

function App() {
  

  return (
    <OpenProvider>
      {false ? <LoginRoutes  /> : <LogOutRoutes />}
      <Footer />
    </OpenProvider>
  );
}

export default App;

