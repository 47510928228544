import React, { useState, useEffect } from 'react';
import toursData from './tour/tourpakage.json'
import Navbarb from './Navbar';
import { Button } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { ShareSocial } from 'react-share-social'


const PackagePage = ({ data }) => {
    const [packageData, setPackageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let url = window.location.href.split('/')[4]
        const tourPackage = toursData.find(tour => tour.slug.toLocaleLowerCase() == url.toLocaleLowerCase());
        setPackageData(tourPackage)
        setLoading(false)
    }, []);
    console.log(packageData);
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error} </div>;
    }

    return (<>
        <Navbarb />
        <Helmet>
            <title>{packageData.name} ||Best Kanchenjunga view rooms from Pelling</title>
            <meta name="description" content={packageData.name} />
            <meta property="og:title" content="Tour Plan of Sikkim ||Best Kanchenjunga view rooms from Pelling" />
            <meta property="og:description" content={Object.entries(packageData.trip).map(([day, activities]) => (
                <div key={day}>
                    <h3>{day}</h3>
                    <ul>
                        {activities.Activities.map((activity, index) => (
                            <li key={index}>{activity}</li>
                        ))}
                    </ul>
                </div>
            ))} />
            <meta property="og:image" content={packageData.images} />
            <img
                src={packageData.images}
            />
        </Helmet>
        <div className='container mt-3'>
            <h1>{packageData.name}</h1>
            <div>
                <img className='o_img bg-loader' src={packageData.images} />

                <div className='mt-3'>
                    <ShareSocial
                        title={packageData.name}
                        
                        url={window.location.href}
                        socialTypes={['line','facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp','telegram']}
                        onSocialButtonClicked={data => console.log(data)}

                    />
                </div>

                <h2>Package Details:</h2>
                <p>Stats: {packageData.stats} /Person</p>
                <p>Info:</p>
                <ul>
                    {packageData.trip.Info.Activities.map((activity, index) => (
                        <li key={index}>{activity}</li>
                    ))}
                </ul>
                <h2>Daily Activities:</h2>
                {Object.entries(packageData.trip).map(([day, activities]) => (
                    <div key={day}>
                        <h3>{day}</h3>
                        <ul>
                            {activities.Activities.map((activity, index) => (
                                <li key={index}>{activity}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
        <a target='_blank' href={`https://wa.me/918777209081?text=  ${packageData.name} Start From ${packageData.stats} Details https://friendshotel.in/tour/${packageData.slug}`}>
            <Button color="blue" fullWidth mt="md" radius="md">
                Book your tour now
            </Button>
        </a>


    </>


    );
};

export default PackagePage;
