import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom'
import Logo from '../../logo/logo.png'
import { Helmet } from 'react-helmet';
import { Container, Card, Paper, Timeline, Text } from '@mantine/core';
import blog from './data/blog.json'
import Navbar from '../Navbar';
import NotFound from '../../NotFound';

const EmotionalGangtokArticle = () => {
    const { language, id } = useParams();
    const [articleData, setArticleData] = useState(null);
    function findArticleByUrl(url) {
        if (language.toUpperCase() == "EN".toUpperCase()) {
            const article = blog.articles.find(article => article.url === url);
            return article ? article : null;
        }
    }
    const location = useLocation()
    useEffect(() => {
        const fetch = findArticleByUrl(id);
        setArticleData(fetch)
    }, [id]);


    return (
        articleData ? <>
            <Helmet>
                <title>{articleData.title}</title>
                <meta name="description" content={articleData.title} />
                <meta name="keywords" content={articleData.title.replace(/ /g, ",")}/>
                <meta property="og:title" content={articleData.title} />
                <meta property="og:description" content={articleData.content} />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content={location.pathname} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Friends Hotel" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:card" content={Logo} />
                <meta name="twitter:title" content={articleData.h1} />
                <meta name="twitter:description" content={articleData.title} />
                <meta name="twitter:image" content={Logo} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Ghsourav" />
                <h1>{articleData.title}</h1>
                {articleData.sections.map((section, index) => (
                    <main key={index}>
                        <h1>{section.title}</h1>
                        <h2>{section.content}</h2>
                    </main>
                ))}

            </Helmet>
            <Navbar />
            <Container size="xl" className='mt-5'>
                <section className='m-3'>
                    <Link to="/blog" className='btn btn-warning'>Back</Link>
                </section>
                <Card shadow="md" padding="lg" radius="md">
                    <Text align="center" size="lg" weight={700}>
                        {articleData.title}
                    </Text>
                </Card>
                <Timeline style={{ marginTop: '20px' }}>
                    {articleData.sections.map((section, index) => (
                        <Timeline.Item key={index} color="blue" title={section.title}>
                            <Text>{section.content}</Text>
                        </Timeline.Item>
                    ))}
                </Timeline>
                <Card shadow="md" padding="lg" radius="md" style={{ marginTop: '20px' }}>
                    <Text align="center" size="lg" weight={700}>
                        Conclusion
                    </Text>
                    <Text>{articleData.conclusion}</Text>
                </Card>
                <Paper style={{ marginTop: '20px', padding: '20px', textAlign: 'center' }}>
                    <Text>
                        {articleData.callToAction}
                    </Text>
                </Paper>
                <section className='mt-4'>

                </section>
            </Container>
        </> : <NotFound />

    );
};

export default EmotionalGangtokArticle;
