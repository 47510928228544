import React from 'react';
import { Card, Image, Text, Badge } from '@mantine/core';
import { isMobile } from 'react-device-detect';
import { useState, useEffect } from 'react';
import './Property.css'

const hotels = [
    {
        name: 'Hotel Bella Casa, Gangtok',
        location: 'Baluwakhani Road, near Vajra Stand, Sungava, Gangtok, Sikkim 737101',
        imageSrc: 'https://ik.imagekit.io/ghsourav/Hotel%20BellaCasa/bellaCasa_JNu4r6y-Q',
        booking: 'https://hotelbellacasa.in/',
        tag: 'Gangtok'
    },
    {
        name: 'Norbulingka Retreat, Pelling, Sikim',
        location: 'Pelling, Sikim',
        tag: 'Pelling',
        imageSrc: 'https://ik.imagekit.io/ghsourav/FriendsHotel/Pelling/230720312.jpg_k=a71f63f07505e240ac7908b27400aa7d68a3cefa71025a7333a2fc006535f471&o=?updatedAt=1691311758191',
        booking: 'https://friendshotel.in/Hotel/Norbulingka-Retreat',
    },
];

const HotelCard = ({ name, location, imageSrc, tag, booking, loading }) => (
    <div >
        <Card shadow="xs" padding="xs" style={{ margin: '0 auto' ,height:'28rem'}}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <Image src={imageSrc} alt="With default placeholder" withPlaceholder height='15rem' style={{ objectFit: 'cover' }} />
                    <Text size="xl" weight={700} style={{ marginTop: 8 }}>
                        <i className="fa fa-h-square" aria-hidden="true"></i>
                        {name}
                    </Text>
                    <Text size="sm" style={{ marginTop: 4 }}>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Location: {location}
                    </Text>
                    <Text size="sm" style={{ marginTop: 4 }}>
                        {booking ? (
                            <a className='btn btn-warning w-100' target="_blank" href={booking}>
                                Visit Now
                            </a>
                        ) : (
                            <button className='btn btn-secondary w-100' disabled> Opening in October </button>
                        )}
                    </Text>
                    <Badge color="cyan" style={{ marginTop: 8 }}>
                        {tag}
                    </Badge>
                </>
            )}
        </Card>
    </div>


);

const OurProperties = () => {
    const [loading, setLoading] = useState(true)
    let res =
        useEffect(() => {
            // Simulating loading delay
            const timer = setTimeout(() => {
                setLoading(false);
            }, 2234);

            // Clean up the timer on component unmount
            return () => clearTimeout(timer);
        }, []);

    return (
        <>
            <div >
                <h1 className='mt-3 text-center p-h1'>Our Properties</h1>
                <main className='container'>
                    <div className='mt-3 row'>
                        {hotels.map((hotel) => (
                            <div key={hotel.name} className='col-lg-4 col-md-6 col-sm-12 bg-light '>
                                <HotelCard {...hotel} loading={loading} />
                            </div>
                        ))}
                    </div>
                    <div className='mt-4'></div>
                </main>
            </div>

        </>

    );
};

export default OurProperties;
