import React from 'react';
import { Container, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import Navbar from './client/Navbar';

const NotFound = () => {
  return (
    <>
      <Navbar />
      <Container size="sm" style={{ textAlign: 'center', marginTop: '40px' }}>
        <Title order={1} style={{ marginBottom: '20px' }}>
          Page Not Found
        </Title>
        <p>Sorry, the page you are looking for does not exist.</p>
        <Link className="btn btn-info" to="/" >
          Go to Home
        </Link>

      </Container>

    </>

  );
};

export default NotFound;